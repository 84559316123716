var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
//@ts-ignore
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
export const configJSON = require("./config");
toast.configure();
export default class BusinessWorkingDaysAndTimeController extends BlockComponent {
    constructor(props) {
        super(props);
        this.getBusinessHoursdataApi = () => {
            this.setState({ loading: true });
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getBusinessHoursdataApiId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.showBusinessHrUrl);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getBorderStyle = (error) => {
            return error ? "1px solid #FF453A" : "1px solid #757575";
        };
        this.optionDisable = (value) => {
            return value === "" ? true : false;
        };
        this.showTimeInUppercase = (value) => {
            return value.toUpperCase();
        };
        //istanbul ignore next
        this.handleWorkingHoursSelection = (itemId) => {
            const { getSelectionMode, workingDaysError, weekendDaysError } = this.state;
            const updatedWorkingDaysData = this.state.workingDaysData.map((item) => {
                if (itemId !== item.id)
                    return item;
                if (getSelectionMode === "weekdays" && !item.isWeekend) {
                    item.isWeekday = !item.isWeekday;
                    item.isDisableWeekend = item.isWeekday;
                }
                else if (getSelectionMode === "weekends" && !item.isWeekday) {
                    item.isWeekend = !item.isWeekend;
                    item.isDisableWeekday = item.isWeekend;
                }
                return item;
            });
            const workingDays = updatedWorkingDaysData.filter((item) => item.isWeekday);
            const weekendDays = updatedWorkingDaysData.filter((item) => item.isWeekend);
            this.setState({
                workingDaysData: updatedWorkingDaysData,
                workingDays: workingDays,
                workingDaysError: workingDays.length ? "" : workingDaysError,
                weekendDays: weekendDays,
                weekendDaysError: weekendDays.length ? "" : weekendDaysError,
                checkDaysArr: false
            });
        };
        this.handleBackgroundColor = (item) => {
            const isWeekday = item.isWeekday;
            const isWeekend = item.isWeekend;
            const selectionMode = this.state.getSelectionMode;
            let backgroundColor = "#f9f9f9";
            if ((selectionMode === "weekdays" && isWeekday) || (selectionMode === "weekends" && isWeekend)) {
                backgroundColor = "#1E5FEA";
            }
            return backgroundColor;
        };
        this.handleColor = (item) => {
            const isWeekday = item.isWeekday;
            const isWeekend = item.isWeekend;
            const selectionMode = this.state.getSelectionMode;
            let color = "#808080";
            if ((selectionMode === "weekdays" && isWeekday) || (selectionMode === "weekends" && isWeekend)) {
                color = "#FFFFFF";
            }
            return color;
        };
        this.onWeekendFromSelection = (selectedItem) => {
            this.setState({ isWeekendToDisable: false, weekendFrom: selectedItem, weekendHoursError: "" });
            this.setWeekendTimeToArr(selectedItem);
        };
        this.onWorkingFromSelection = (selectedItem) => {
            this.setState({ isWorkingToDisable: false, workingFrom: selectedItem, workingHoursError: "" });
            this.setWorkingTimeToArr(selectedItem);
        };
        this.handleSelectToggle = (key) => {
            this.setState(prevState => {
                const newState = Object.assign(Object.assign({}, prevState), { [key]: !prevState[key] });
                return newState;
            });
        };
        this.getTextColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? 'white' : 'black';
        };
        this.getBackGroundColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "#1e5fea" : undefined;
        };
        this.handleReceive = (responseJson) => {
            if (responseJson) {
                this.setState({ responseTeamList: responseJson === null || responseJson === void 0 ? void 0 : responseJson.data, loading: false });
                this.setDataOfSingleUser(responseJson);
            }
            else {
                this.setState({ responseTeamList: [], loading: false });
            }
        };
        //istanbul ignore next
        this.setDataOfSingleUser = (responseJson) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
            this.setState({ isWorkingToDisable: false, workingHoursError: "", weekendFrom: (_b = (_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.weekend_hours_from.toLowerCase(), isWeekendToDisable: false, weekendTo: (_d = (_c = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.weekend_hours_to.toLowerCase(), weekendHoursError: '', workingFrom: (_f = (_e = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _e === void 0 ? void 0 : _e.attributes) === null || _f === void 0 ? void 0 : _f.open_from.toLowerCase(), workingTo: (_h = (_g = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _g === void 0 ? void 0 : _g.attributes) === null || _h === void 0 ? void 0 : _h.close_at.toLowerCase() }, () => {
                var _a, _b, _c, _d;
                switch (7) {
                    case this.state.workingFrom.length:
                        this.setState({ workingFrom: '0' + this.state.workingFrom });
                        break;
                    case this.state.workingTo.length:
                        this.setState({ workingTo: '0' + this.state.workingTo });
                        break;
                    case this.state.weekendFrom.length:
                        this.setState({ weekendFrom: '0' + this.state.weekendFrom });
                        break;
                    case this.state.weekendTo.length:
                        this.setState({ weekendTo: '0' + this.state.weekendTo });
                        break;
                    default:
                        break;
                }
                this.setWeekendTimeToArr((_b = (_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.weekend_hours_from.toLowerCase());
                this.setWorkingTimeToArr((_d = (_c = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.open_from.toLowerCase());
            });
            if (((_l = (_k = (_j = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _j === void 0 ? void 0 : _j.attributes) === null || _k === void 0 ? void 0 : _k.working_days) === null || _l === void 0 ? void 0 : _l.length) !== 0) {
                let working_days = (_o = (_m = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _m === void 0 ? void 0 : _m.attributes) === null || _o === void 0 ? void 0 : _o.working_days.map(function (x) { return x.toUpperCase(); });
                let updateWorkday = (_p = this.state.workingDaysData) === null || _p === void 0 ? void 0 : _p.map((ele, index) => {
                    if (working_days.includes(ele.day.toUpperCase())) {
                        let temp = Object.assign({}, ele);
                        temp.isWeekday = true;
                        temp.isDisableWeekend = true;
                        return temp;
                    }
                    else {
                        return ele;
                    }
                });
                this.setState({
                    workingDaysData: updateWorkday,
                    workingDays: (_r = (_q = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _q === void 0 ? void 0 : _q.attributes) === null || _r === void 0 ? void 0 : _r.working_days
                });
            }
            if (((_u = (_t = (_s = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _s === void 0 ? void 0 : _s.attributes) === null || _t === void 0 ? void 0 : _t.weekends) === null || _u === void 0 ? void 0 : _u.length) !== 0) {
                let weekends = (_w = (_v = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _v === void 0 ? void 0 : _v.attributes) === null || _w === void 0 ? void 0 : _w.weekends.map(function (x) { return x.toUpperCase(); });
                let updateWorkday = (_x = this.state.workingDaysData) === null || _x === void 0 ? void 0 : _x.map((ele, index) => {
                    if (weekends.includes(ele.day.toUpperCase())) {
                        let temp = Object.assign({}, ele);
                        temp.isWeekend = true;
                        temp.isDisableWeekday = true;
                        return temp;
                    }
                    else {
                        return ele;
                    }
                });
                this.setState({
                    workingDaysData: updateWorkday,
                    weekendDays: (_z = (_y = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _y === void 0 ? void 0 : _y.attributes) === null || _z === void 0 ? void 0 : _z.weekends
                });
            }
        };
        this.checkDisablity = () => {
            var _a, _b;
            if (((_a = this.state.workingDays) === null || _a === void 0 ? void 0 : _a.length) !== 0 && ((_b = this.state.weekendDays) === null || _b === void 0 ? void 0 : _b.length) !== 0 && this.state.weekendTo && this.state.weekendFrom && this.state.workingTo && this.state.workingFrom) {
                let btn = document.querySelector('#updateData_btn');
                btn && btn.setAttribute("style", 'color: #ffffff; cursor: pointer;');
                return false;
            }
            else {
                let btn = document.querySelector('#updateData_btn');
                btn && btn.setAttribute("style", 'color: #ffffff63; cursor: no-drop;');
                return true;
            }
        };
        this.setWorkingTimeToArr = (workingFrom) => {
            let nextToarr = [];
            this.state.workingSlot.forEach((element) => {
                let from_today = moment().format('MM/DD/YYYY') + ' ' + workingFrom;
                let To_today = moment().format('MM/DD/YYYY') + ' ' + element;
                let aDate = new Date(from_today).getTime();
                let bDate = new Date(To_today).getTime();
                if (aDate < bDate) {
                    nextToarr.push(element);
                }
            });
            if (workingFrom !== '11:30 pm') {
                this.setState({ workingSlotTo: nextToarr });
            }
            else {
                this.setState({
                    workingSlotTo: [
                        "12:00 am",
                        "12:30 am",
                        "01:00 am",
                        "01:30 am",
                        "02:00 am",
                        "02:30 am",
                        "03:00 am",
                        "03:30 am",
                        "04:00 am",
                        "04:30 am",
                        "05:00 am",
                        "05:30 am",
                        "06:00 am",
                        "06:30 am",
                        "07:00 am",
                        "07:30 am",
                        "08:00 am",
                        "08:30 am",
                        "09:00 am",
                        "09:30 am",
                        "10:00 am",
                        "10:30 am",
                        "11:00 am",
                        "11:30 am",
                        "12:00 pm",
                        "12:30 pm",
                        "01:00 pm",
                        "01:30 pm",
                        "02:00 pm",
                        "02:30 pm",
                        "03:00 pm",
                        "03:30 pm",
                        "04:00 pm",
                        "04:30 pm",
                        "05:00 pm",
                        "05:30 pm",
                        "06:00 pm",
                        "06:30 pm",
                        "07:00 pm",
                        "07:30 pm",
                        "08:00 pm",
                        "08:30 pm",
                        "09:00 pm",
                        "09:30 pm",
                        "10:00 pm",
                        "10:30 pm",
                        "11:00 pm"
                    ], workingTo: '12:00 am'
                });
            }
        };
        this.setWeekendTimeToArr = (workingFrom) => {
            let nextToarr = [];
            this.state.workingSlot.forEach((element) => {
                let from_today = moment().format('MM/DD/YYYY') + ' ' + workingFrom;
                let To_today = moment().format('MM/DD/YYYY') + ' ' + element;
                let aDate = new Date(from_today).getTime();
                let bDate = new Date(To_today).getTime();
                if (aDate < bDate) {
                    nextToarr.push(element);
                }
            });
            if (workingFrom !== '11:30 pm') {
                this.setState({ weekendSlotTo: nextToarr });
            }
            else {
                this.setState({
                    weekendSlotTo: [
                        "12:00 am",
                        "12:30 am",
                        "01:00 am",
                        "01:30 am",
                        "02:00 am",
                        "02:30 am",
                        "03:00 am",
                        "03:30 am",
                        "04:00 am",
                        "04:30 am",
                        "05:00 am",
                        "05:30 am",
                        "06:00 am",
                        "06:30 am",
                        "07:00 am",
                        "07:30 am",
                        "08:00 am",
                        "08:30 am",
                        "09:00 am",
                        "09:30 am",
                        "10:00 am",
                        "10:30 am",
                        "11:00 am",
                        "11:30 am",
                        "12:00 pm",
                        "12:30 pm",
                        "01:00 pm",
                        "01:30 pm",
                        "02:00 pm",
                        "02:30 pm",
                        "03:00 pm",
                        "03:30 pm",
                        "04:00 pm",
                        "04:30 pm",
                        "05:00 pm",
                        "05:30 pm",
                        "06:00 pm",
                        "06:30 pm",
                        "07:00 pm",
                        "07:30 pm",
                        "08:00 pm",
                        "08:30 pm",
                        "09:00 pm",
                        "09:30 pm",
                        "10:00 pm",
                        "10:30 pm",
                        "11:00 pm"
                    ], weekendTo: '12:00 am'
                });
            }
        };
        this.updateBusinessdetails = () => {
            this.setState({ loading: true });
            let workingDays = [];
            let weekendDays = [];
            if (!this.state.checkDaysArr) {
                this.state.workingDays.forEach((data) => {
                    workingDays.push(data === null || data === void 0 ? void 0 : data.day);
                });
                this.state.weekendDays.forEach((data) => {
                    weekendDays.push(data === null || data === void 0 ? void 0 : data.day);
                });
            }
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                data: {
                    open_from: this.state.workingFrom,
                    close_at: this.state.workingTo,
                    working_days: this.state.checkDaysArr ? this.state.workingDays : workingDays,
                    weekends: this.state.checkDaysArr ? this.state.weekendDays : weekendDays,
                    weekend_hours_from: this.state.weekendFrom,
                    weekend_hours_to: this.state.weekendTo,
                }
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateBusinessdataId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateBusinessHrurl);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putApiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleReceiveUserResponse = (responseJson) => {
            this.setState({ loading: false });
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.error) {
                toast.error(this.props.t(responseJson === null || responseJson === void 0 ? void 0 : responseJson.error), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit",
                    },
                });
                this.getBusinessHoursdataApi();
            }
            else {
                toast.success(this.props.t("Details are updated."), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit",
                    },
                });
                this.handleReceive(responseJson);
            }
            this.setState({ wantToEdit: false });
        };
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            loading: false,
            workingDays: [],
            responseTeamList: [],
            getSelectionMode: 'weekdays',
            weekendHoursError: '',
            workingHoursError: '',
            workingSlotTo: [],
            weekendSlotTo: [],
            isWorkingToDisable: true,
            workingFrom: '',
            isSelectOpenWeekHoursFrom: false,
            workingTo: "",
            isSelectOpenWeekHoursTo: false,
            weekendFrom: '',
            weekendTo: '',
            isSelectOpenWeekEndsTo: false,
            workingSlot: [
                "",
                "12:00 am",
                "12:30 am",
                "01:00 am",
                "01:30 am",
                "02:00 am",
                "02:30 am",
                "03:00 am",
                "03:30 am",
                "04:00 am",
                "04:30 am",
                "05:00 am",
                "05:30 am",
                "06:00 am",
                "06:30 am",
                "07:00 am",
                "07:30 am",
                "08:00 am",
                "08:30 am",
                "09:00 am",
                "09:30 am",
                "10:00 am",
                "10:30 am",
                "11:00 am",
                "11:30 am",
                "12:00 pm",
                "12:30 pm",
                "01:00 pm",
                "01:30 pm",
                "02:00 pm",
                "02:30 pm",
                "03:00 pm",
                "03:30 pm",
                "04:00 pm",
                "04:30 pm",
                "05:00 pm",
                "05:30 pm",
                "06:00 pm",
                "06:30 pm",
                "07:00 pm",
                "07:30 pm",
                "08:00 pm",
                "08:30 pm",
                "09:00 pm",
                "09:30 pm",
                "10:00 pm",
                "10:30 pm",
                "11:00 pm",
                "11:30 pm",
            ],
            workingDaysError: "",
            workingDaysData: [
                {
                    id: 1,
                    day: "Sunday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 2,
                    day: "Monday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 3,
                    day: "Tuesday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 4,
                    day: "Wednesday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 5,
                    day: "Thursday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 6,
                    day: "Friday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
                {
                    id: 7,
                    day: "Saturday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false
                },
            ],
            isWeekendToDisable: true,
            isSelectOpenWeekEndsFrom: false,
            weekendDaysError: "",
            weekendDays: [],
            checkDaysArr: true,
            wantToEdit: false
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let userToken = localStorage.getItem("token") || "";
            let typeUser = localStorage.getItem("userType") || "";
            if (typeUser != "business" || !userToken) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                this.getBusinessHoursdataApi();
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getBusinessHoursdataApiId) {
                    this.handleReceive(responseJson);
                }
                if (apiRequestCallId === this.updateBusinessdataId) {
                    this.handleReceiveUserResponse(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
