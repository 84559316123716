// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import ManageEmployee from "./ManageEmployee.web";
import { IconButton, Button, Card, CardContent, Typography, OutlinedInput, InputAdornment, FormControl, Dialog, DialogContent, TextField, Box, } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EmployeeBlockMyTimeController from "./EmployeeBlockMyTimeController";
import { backdrop, whiteAddbutton, arrowRight, calender, ArrowDown, editIcon, alertIcon, } from "../assets";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
class EmployeeManageTab extends EmployeeBlockMyTimeController {
    constructor(props) {
        super(props);
        this.handleDateChange = (date) => {
            console.log("handleDateChange ::", date);
            const formattedDate = moment(date).format("DD MMM YYYY");
            if (this.state.modaltype === "startDate") {
                this.setState({ startDate: formattedDate, DateError: "" });
            }
            else if (this.state.modaltype === "endDate") {
                this.setState({ endDate: formattedDate, endDateError: "" });
            }
        };
        this.handaleClose = () => {
            this.setState({ openCalenderDialog: false, selectDateError: "" });
        };
        this.handleOngoing = () => {
            if (this.state.modaltype === "startDate") {
                this.setState({
                    startDate: "ongoing",
                    DateError: "",
                    openCalenderDialog: false,
                });
            }
            else {
                this.setState({
                    endDate: "ongoing",
                    endDateError: "",
                    openCalenderDialog: false,
                });
            }
        };
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        let { t } = this.props;
        return (React.createElement("div", { className: "manage-employee-main-container" },
            React.createElement("div", null,
                React.createElement(ManageEmployee, null)),
            React.createElement("div", { className: webAppDirection === "ltr"
                    ? "manage-employee-content-container"
                    : "arabic-manage-employee-content-container" },
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "manage-employee-heading-container" },
                        React.createElement("div", { className: "employee-back-icon", style: { display: "flex", alignItems: "center" } },
                            React.createElement(IconButton, { style: { width: "30px", height: "30px" }, id: "removeImage", onClick: () => {
                                    this.handaleBackArrowButton();
                                } },
                                React.createElement("img", { src: backdrop, alt: "logo", width: "30px", height: "30px" })),
                            React.createElement("span", { className: "manage-employee-header-text" }, `${window.localStorage.getItem("teammembername") || ""} ${t("block days & time")}`))),
                    React.createElement("div", { className: "employee-card-container" },
                        this.state.tabName === "none" && (React.createElement("div", { className: "employee-card" },
                            React.createElement("div", { className: "block-days-time-container" },
                                this.state.blockData.map((blockDay) => {
                                    var _a, _b;
                                    let [fromTime, fromAmPm] = ((_a = blockDay.attributes.start_time) === null || _a === void 0 ? void 0 : _a.split(" ")) || [];
                                    let [toTime, toAmPm] = ((_b = blockDay.attributes.end_time) === null || _b === void 0 ? void 0 : _b.split(" ")) || [];
                                    return (React.createElement("div", { key: blockDay.name },
                                        React.createElement(Card, { className: "card-box-class" },
                                            React.createElement(CardContent, null,
                                                React.createElement(Typography, { variant: "h6" }, React.createElement("div", { className: "employee-service-name" },
                                                    React.createElement("span", { className: "employee-service-name" }, blockDay.attributes.description))),
                                                React.createElement(Typography, { variant: "body1" }, React.createElement("div", { className: "employee-container" },
                                                    React.createElement("span", { className: "employee-label-left" }, `${blockDay.attributes.from_date} - ${blockDay.attributes.to_date}`),
                                                    React.createElement("span", { style: {
                                                            marginLeft: webAppDirection === "ltr"
                                                                ? "auto"
                                                                : "",
                                                            marginRight: webAppDirection === "rtl"
                                                                ? "auto"
                                                                : "",
                                                        } },
                                                        React.createElement(IconButton, { color: "primary", "aria-label": "upload picture", component: "span", id: "editBlock", 
                                                            //instanbul ignore next
                                                            onClick: () => {
                                                                this.handleEditBlockTime(blockDay.attributes.id);
                                                            } },
                                                            React.createElement("img", { src: arrowRight, alt: "logo", width: "24px", height: "24px" }))))),
                                                React.createElement(Typography, { variant: "body1" }, React.createElement("div", { className: "employee-label-left" },
                                                    React.createElement("span", { className: "employee-label-left" },
                                                        " ",
                                                        `${fromTime} ${t(fromAmPm)} - ${toTime} ${t(toAmPm)}`)))))));
                                }),
                                !this.state.blockData.length && !this.state.loading && (React.createElement("div", { style: { textAlign: "center" } }, t("Block days and time not found")))),
                            React.createElement("div", { className: "block-days-time-button" },
                                React.createElement(Button, { variant: "contained", className: "block-days-time-Add-button", color: "primary", onClick: () => {
                                        this.setState({ tabName: "addBlockTime" });
                                    } },
                                    React.createElement("img", { src: whiteAddbutton, alt: "type", width: "17px", height: "17px" }),
                                    " ",
                                    "\u00A0 ",
                                    t("Add another"))))),
                        this.state.tabName === "addBlockTime" && (React.createElement("div", { className: "employee-card" },
                            React.createElement("div", { className: "employee-add-block-my-time-label" }, t("Days")),
                            React.createElement("div", { style: {
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "24px",
                                    marginTop: 24,
                                    maxWidth: "71%",
                                } },
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                    } },
                                    React.createElement("label", { htmlFor: "outlined-adornment-password-1", className: "employee-add-block-my-time-sublabel" }, t("From")),
                                    React.createElement(FormControl, { variant: "outlined" },
                                        React.createElement(OutlinedInput, { id: "calendar1", type: "text", error: this.state.DateError, onBlur: () => {
                                                this.onDateChange(this.state.startDate, "start");
                                            }, placeholder: t("Select date"), value: this.state.startDate, style: {
                                                fontFamily: "Poppins-Medium, sans-serif",
                                                borderRadius: "8px",
                                            }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                React.createElement(IconButton, { id: "openCalenderstartTime", className: "openCalenderstartTime", 
                                                    //instanbul ignore next
                                                    onClick: () => {
                                                        this.setState({
                                                            openCalenderDialog: true,
                                                            modaltype: "startDate",
                                                        });
                                                    }, "aria-label": "toggle password visibility", edge: "end" },
                                                    React.createElement("img", { src: calender, alt: "type", width: "25px", height: "26px" }))) })),
                                    React.createElement("div", { style: { marginTop: 10, display: "flex" } },
                                        React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.DateError))))),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                    } },
                                    React.createElement("label", { htmlFor: "outlined-adornment-password-2", className: "employee-add-block-my-time-sublabel" }, t("To")),
                                    React.createElement(FormControl, { variant: "outlined" },
                                        React.createElement(OutlinedInput, { id: "calender-endtime", type: "text", error: this.state.endDateError, value: this.state.endDate, placeholder: t("Select date"), onBlur: () => {
                                                this.onDateChange(this.state.endDate, "end");
                                            }, style: {
                                                fontFamily: "Poppins-Medium, sans-serif",
                                                borderRadius: "8px",
                                            }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                React.createElement(IconButton, { onClick: () => {
                                                        this.setState({
                                                            openCalenderDialog: true,
                                                            modaltype: "endDate",
                                                        });
                                                    }, "aria-label": "toggle password visibility", edge: "end" },
                                                    React.createElement("img", { src: calender, alt: "type", width: "25px", height: "26px" }))) })),
                                    React.createElement("div", { style: { marginTop: 10, display: "flex" } },
                                        React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.endDateError)))))),
                            React.createElement("div", { className: "employee-add-block-my-time-label" }, t("Time")),
                            React.createElement("div", { style: {
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "24px",
                                    marginTop: 24,
                                    maxWidth: "100%",
                                } },
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "50%",
                                    } },
                                    React.createElement("label", { htmlFor: "outlined-adornment-password-1", className: "employee-add-block-my-time-sublabel" }, t("From")),
                                    React.createElement(FormControl, { variant: "outlined" },
                                        React.createElement(Autocomplete, { id: "startTime", options: this.state.timDataStart, getOptionLabel: (option) => this.getOptionText(option), style: { width: "100%", minWidth: "234px" }, onChange: (event, value) => {
                                                this.onWeekendFromSelection(value);
                                            }, popupIcon: React.createElement("img", { src: ArrowDown, alt: "Dropdown Icon", width: 24, height: 24 }), className: "my-autocomplete", renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { placeholder: t("Select time"), variant: "outlined", id: "startTimeAuto", error: this.state.TimeError, inputProps: Object.assign(Object.assign({}, params.inputProps), { readOnly: true }), onBlur: () => {
                                                    this.onDateChange(this.state.startTime, "startTime");
                                                } }))) })),
                                    React.createElement("div", { style: { marginTop: 10 } },
                                        React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.TimeError))))),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "50%",
                                    } },
                                    React.createElement("label", { htmlFor: "outlined-adornment-password-2", className: "employee-add-block-my-time-sublabel" }, t("To")),
                                    React.createElement(FormControl, { variant: "outlined" },
                                        React.createElement(Autocomplete, { id: "endtime", options: this.state.timDataEnd, getOptionLabel: (option) => this.getOptionText(option), style: { width: "100%", minWidth: "234px" }, className: "my-autocomplete", onChange: (event, value) => {
                                                this.setState({ endTime: value, endTimeError: "" });
                                            }, popupIcon: React.createElement("img", { src: ArrowDown, alt: "Dropdown Icon", width: 24, height: 24 }), renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { placeholder: t("Select time"), error: this.state.endTimeError, variant: "outlined", inputProps: Object.assign(Object.assign({}, params.inputProps), { readOnly: true }), onBlur: () => {
                                                    this.onDateChange(this.state.endTime, "endTime");
                                                } }))) })),
                                    React.createElement("div", { style: { marginTop: 10 } },
                                        React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.endTimeError)))))),
                            React.createElement("div", { style: { display: "flex", marginTop: 24, maxWidth: "100%" } },
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        maxWidth: "100%",
                                    } },
                                    React.createElement("label", { htmlFor: "outlined-adornment-password-1", className: "employee-add-block-my-time-sublabel" }, t("Description")),
                                    React.createElement(FormControl, { variant: "outlined" },
                                        React.createElement(OutlinedInput, { id: "Description", type: "text", onBlur: () => {
                                                this.onDateChange(this.state.desc, "desc");
                                            }, onChange: (e) => {
                                                //instanbul ignore next
                                                this.setState({ desc: e.target.value });
                                            }, error: this.state.descError, placeholder: t("Eg: Break"), className: "employe-card-description", inputProps: {
                                                maxLength: 100,
                                                min: 0,
                                            } })),
                                    React.createElement("div", { style: { marginTop: 10 } },
                                        React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.descError)))))),
                            React.createElement("div", { style: {
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: 60,
                                    marginBottom: "55px",
                                    maxWidth: "100%",
                                } },
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: webAppDirection === "rtl" ? "44px" : "0px",
                                    } },
                                    React.createElement(Button, { variant: "contained", color: "primary", className: "employee-add-block-my-time-button cancel", onClick: () => {
                                            this.handaleCancel();
                                        } }, t("Cancel"))),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: webAppDirection === "ltr" ? "24px" : "",
                                    } },
                                    React.createElement(Button, { variant: "contained", color: "primary", id: "addsaveButton", className: "employee-add-block-my-time-button save", onClick: () => {
                                            this.submitData("add");
                                        } }, t("Save")))))),
                        this.state.tabName === "editBlockTime" && (React.createElement("div", { className: "employee-card" },
                            React.createElement("div", { className: "employee-add-block-my-time-label" }, t("Days")),
                            React.createElement("div", { style: {
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "24px",
                                    marginTop: 24,
                                    maxWidth: "71%",
                                } },
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                    } },
                                    React.createElement("label", { htmlFor: "outlined-adornment-password-1", className: "employee-add-block-my-time-sublabel" }, t("From")),
                                    React.createElement(FormControl, { variant: "outlined" },
                                        React.createElement(OutlinedInput, { id: "calenderstartTime", type: "text", disabled: this.state.viewMode, error: this.state.DateError, onBlur: () => {
                                                this.onDateChange(this.state.startDate, "start");
                                            }, placeholder: t("Select date"), value: this.state.startDate, style: {
                                                fontFamily: "Poppins-Medium, sans-serif",
                                                borderRadius: "8px",
                                            }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                React.createElement(IconButton, { disabled: this.state.viewMode, onClick: () => {
                                                        this.setState({
                                                            openCalenderDialog: true,
                                                            modaltype: "startDate",
                                                        });
                                                    }, "aria-label": "toggle password visibility", edge: "end" },
                                                    React.createElement("img", { src: calender, alt: "type", width: "25px", height: "26px" }))) })),
                                    React.createElement("div", { style: { marginTop: 10 } },
                                        React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.DateError))))),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                    } },
                                    React.createElement("label", { htmlFor: "outlined-adornment-password-2", className: "employee-add-block-my-time-sublabel" }, t("To")),
                                    React.createElement(FormControl, { variant: "outlined" },
                                        React.createElement(OutlinedInput, { id: "calenderEndTime", type: "text", error: this.state.endDateError, disabled: this.state.viewMode, onBlur: () => {
                                                this.onDateChange(this.state.endDate, "end");
                                            }, placeholder: t("Select date"), value: this.state.endDate, style: {
                                                fontFamily: "Poppins-Medium, sans-serif",
                                                borderRadius: "8px",
                                            }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                React.createElement(IconButton, { disabled: this.state.viewMode, className: "toggle-visibility", onClick: () => {
                                                        this.setState({
                                                            openCalenderDialog: true,
                                                            modaltype: "endDate",
                                                        });
                                                    }, "aria-label": "toggle password visibility", edge: "end" },
                                                    React.createElement("img", { src: calender, alt: "type", width: "25px", height: "26px" }))) })),
                                    React.createElement("div", { style: { marginTop: 10 } },
                                        React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.endDateError)))))),
                            React.createElement("div", { className: "employee-add-block-my-time-label" }, t("Time")),
                            React.createElement("div", { style: {
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "24px",
                                    marginTop: 24,
                                    maxWidth: "100%",
                                } },
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "50%",
                                    } },
                                    React.createElement("label", { htmlFor: "outlined-adornment-password-1", className: "employee-add-block-my-time-sublabel" }, t("From")),
                                    React.createElement(FormControl, { variant: "outlined" },
                                        React.createElement(Autocomplete, { id: "editstartTime", options: this.state.timDataStart, getOptionLabel: (option) => this.getOptionText(option), style: { width: "100%", minWidth: "234px" }, disabled: this.state.viewMode, value: this.state.startTime, className: "my-autocomplete", onChange: (event, value) => {
                                                this.onWeekendFromSelection(value);
                                            }, popupIcon: React.createElement("img", { src: ArrowDown, alt: "Dropdown Icon", width: 24, height: 24 }), renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { placeholder: t("Select time"), variant: "outlined", error: this.state.TimeError, inputProps: Object.assign(Object.assign({}, params.inputProps), { readOnly: true }), onBlur: () => {
                                                    this.onDateChange(this.state.startTime, "startTime");
                                                } }))) })),
                                    React.createElement("div", { style: { marginTop: 10 } },
                                        React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.TimeError))))),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "50%",
                                    } },
                                    React.createElement("label", { htmlFor: "outlined-adornment-password-2", className: "employee-add-block-my-time-sublabel" }, t("To")),
                                    React.createElement(FormControl, { variant: "outlined" },
                                        React.createElement(Autocomplete, { id: "editendTime", options: this.state.timDataEnd, getOptionLabel: (option) => this.getOptionText(option), style: { width: "234px" }, disabled: this.state.viewMode, className: "my-autocomplete", value: this.state.endTime, onChange: (event, value) => {
                                                this.setState({ endTime: value, endTimeError: "" });
                                            }, popupIcon: React.createElement("img", { src: ArrowDown, alt: "Dropdown Icon", width: 24, height: 24 }), renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { placeholder: t("Select time"), variant: "outlined", error: this.state.endTimeError, inputProps: Object.assign(Object.assign({}, params.inputProps), { readOnly: true }), onBlur: () => {
                                                    this.onDateChange(this.state.endTime, "endTime");
                                                } }))) })),
                                    React.createElement("div", { style: { marginTop: 10 } },
                                        React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.endTimeError)))))),
                            React.createElement("div", { style: { display: "flex", marginTop: 24, maxWidth: "100%" } },
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        maxWidth: "100%",
                                    } },
                                    React.createElement("label", { htmlFor: "outlined-adornment-password-1", className: "employee-add-block-my-time-sublabel" }, t("Description")),
                                    React.createElement(FormControl, { variant: "outlined" },
                                        React.createElement(OutlinedInput, { id: "editDescription", type: "text", disabled: this.state.viewMode, onBlur: () => {
                                                this.onDateChange(this.state.desc, "desc");
                                            }, value: this.state.desc, error: this.state.descError, onChange: (e) => {
                                                //instanbul ignore next
                                                this.setState({ desc: e.target.value });
                                            }, inputProps: {
                                                maxLength: 100,
                                                min: 0,
                                            }, placeholder: t("Eg: Break"), style: {
                                                width: "492px",
                                                height: "54px",
                                                borderRadius: "8px",
                                                fontFamily: "Poppins-Medium, sans-serif",
                                            } })),
                                    React.createElement("div", { style: { marginTop: 10 } },
                                        React.createElement("span", { className: "manage-employee-error-message-text" }, t(this.renderErrorMessageforBlock(this.state.descError)))))),
                            this.state.viewMode && (React.createElement("div", { style: {
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: 60,
                                    marginBottom: "50px",
                                    maxWidth: "100%",
                                } },
                                React.createElement("div", { style: {
                                        display: "flex",
                                        marginLeft: webAppDirection === "rtl" ? "44px" : "0px",
                                    } },
                                    React.createElement(Button, { variant: "contained", color: "primary", className: "employee-add-block-my-time-button edit", onClick: () => {
                                            this.setState({ viewMode: false });
                                        } },
                                        React.createElement("img", { src: editIcon, alt: "type", width: "14px", height: "16px" }),
                                        "\u00A0",
                                        t("Edit"))),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        marginLeft: webAppDirection === "ltr" ? "24px" : "",
                                    } },
                                    React.createElement(Button, { variant: "contained", color: "primary", className: "employee-add-block-my-time-button delete", onClick: () => {
                                            this.setState({ alertDialogOpen: true });
                                        } }, t("Delete"))))),
                            !this.state.viewMode && (React.createElement("div", { style: {
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: 60,
                                    marginBottom: "55px",
                                } },
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: webAppDirection === "rtl" ? "44px" : "0px",
                                    } },
                                    React.createElement(Button, { variant: "contained", color: "primary", className: "employee-add-block-my-time-button cancel", onClick: () => {
                                            this.handaleCancel();
                                        } }, t("Cancel"))),
                                React.createElement("div", { style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: webAppDirection === "ltr" ? "24px" : "",
                                    } },
                                    React.createElement(Button, { variant: "contained", color: "primary", id: "editsaveButton", className: "employee-add-block-my-time-button save", onClick: () => {
                                            this.submitData("edit");
                                        } }, t("Save")))))))))),
            this.state.alertDialogOpen && (React.createElement(Dialog, { open: this.state.alertDialogOpen, PaperProps: {
                    style: {
                        width: "427px",
                        height: "480px",
                        borderRadius: "24px",
                        boxShadow: "none",
                        background: "#FFFFFF",
                    },
                } },
                React.createElement(DialogContent, null,
                    React.createElement("div", { className: "employee-alert-dialog" },
                        React.createElement("div", { className: "employee-alert-dialog-icon" },
                            React.createElement("img", { src: alertIcon, alt: "type", width: "120px", height: "120px" })),
                        React.createElement("div", { className: "employee-alert-dialog-text" },
                            React.createElement("span", { className: "employee-alert-dialog-text-value" }, t("Are you sure you want to")),
                            React.createElement("span", { className: "employee-alert-dialog-text-value" }, t("delete this Block days&time?"))),
                        React.createElement("div", { className: "employee-alert-dialog-buttons" },
                            React.createElement("div", null,
                                React.createElement(Button, { variant: "contained", color: "primary", className: "employee-add-block-my-time-button noButton", onClick: () => {
                                        this.setState({ alertDialogOpen: false });
                                    } }, t("No"))),
                            React.createElement("div", null,
                                React.createElement(Button, { variant: "contained", color: "primary", className: "employee-add-block-my-time-button yesButton", onClick: () => {
                                        this.submitData("delete");
                                    } }, t("Yes")))))))),
            React.createElement(Dialog, { open: this.state.openCalenderDialog, PaperProps: {
                    style: {
                        height: "auto",
                        width: "403px",
                        background: "#FFFFFF",
                        boxShadow: "none",
                        borderRadius: "24px",
                    },
                } },
                React.createElement(DialogContent, null,
                    React.createElement(Box, { mb: 0.5 },
                        React.createElement("div", { style: {
                                marginLeft: "10px",
                            } },
                            React.createElement("div", { className: "add-member-calendar-container" },
                                React.createElement("button", { onClick: () => this.setState({ openCalenderDialog: false }), className: "calendar-close" }, "\u00D7"),
                                React.createElement(Calendar, { calendarType: "US", onChange: this.handleDateChange, formatMonthYear: (locale, date) => date.toLocaleString(locale, {
                                        month: "short",
                                        year: "numeric",
                                    }) }),
                                React.createElement("div", { style: {
                                        marginTop: "35px",
                                        gap: 10,
                                        display: "flex",
                                    } },
                                    React.createElement("button", { style: {
                                            height: "52px",
                                            width: "130px",
                                            borderRadius: "28px",
                                            marginLeft: "30px",
                                            border: "1px solid #2C2C2E",
                                        }, disabled: this.state.modaltype === "startDate", id: "ongoing", onClick: () => {
                                            this.handleOngoing();
                                        } }, t("Ongoing")),
                                    React.createElement("button", { id: "onDone", style: {
                                            height: "52px",
                                            width: "130px",
                                            backgroundColor: "#1E5FEA",
                                            borderRadius: "28px",
                                            border: "none",
                                            color: "#FFFFFF",
                                        }, onClick: () => {
                                            this.setState({ openCalenderDialog: false });
                                        } }, t("Done"))))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(EmployeeManageTab));
// Customizable Area End
