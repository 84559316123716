var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export default class CustomisableBusinessSetupWebController extends BlockComponent {
    constructor(props) {
        super(props);
        // web events
        this.setInputValue = (text) => {
            this.setState({ txtInputValue: text });
        };
        this.getOS = () => {
            const platform = navigator.userAgent;
            let os = 'Unknown';
            if (/Win/.test(platform)) {
                os = 'Windows';
            }
            else if (/Mac/.test(platform)) {
                os = 'MacOS';
            }
            else if (/Linux/.test(platform)) {
                os = 'Linux';
            }
            else if (/iPad|iPhone|iPod/.test(platform)) {
                os = 'iOS';
            }
            else if (/Android/.test(platform)) {
                os = 'Android';
            }
            return os;
        };
        this.hasLocationPermission = () => __awaiter(this, void 0, void 0, function* () {
            console.log("hasLocationPermission :", this.getOS());
            if (this.getOS() === "Linux" || this.getOS() === "Windows" || this.getOS() === "Android" || this.getOS() === "MacOS" || this.getOS() === "iOS") {
                if (navigator.geolocation) {
                    return true;
                }
                else {
                    return false;
                }
            }
            return false;
        });
        this.allowLoactionAccess = (value) => {
            this.setState({ allowAccess: value });
        };
        this.getCurrentLocation = (url) => __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch(url);
                const json = yield response.json();
                const latLocation = this.extractAddressComponent(json, "locality");
                const countryName = this.extractAddressComponent(json, "country");
                const stateName = this.extractAddressComponent(json, "administrative_area_level_1");
                const pincode = this.extractAddressComponent(json, "postal_code");
                const landmark = this.extractAddressComponent(json, "sublocality_level_2");
                let address = "";
                if (landmark) {
                    address = `${landmark}, ${latLocation}, ${stateName}`;
                }
                else {
                    address = `${latLocation}, ${stateName}`;
                }
                this.setState({
                    countryName,
                    stateName,
                    cityName: latLocation,
                    postalCode: pincode,
                    landmark,
                    address,
                    businessLocation: latLocation
                });
                if (this.state.address !== "") {
                    this.setState({ addressError: "" });
                }
                if (this.state.postalCode !== "") {
                    this.setState({ postalCodeError: "" });
                }
                if (this.state.cityName !== "") {
                    this.setState({ cityNameError: "" });
                }
                if (this.state.stateName !== "") {
                    this.setState({ stateNameError: "" });
                }
                if (this.state.countryName !== "") {
                    this.setState({ countryNameError: "" });
                }
            }
            catch (e) {
                console.log(e);
            }
        });
        this.extractAddressComponent = (json, type) => {
            console.log("extractAddressComponent :", json);
            const result = json.results[0].address_components.find((component) => component.types.includes(type));
            return result ? result.long_name : "";
        };
        this.getLocation = () => __awaiter(this, void 0, void 0, function* () {
            console.log("user getLocation");
            try {
                const hasLocationPermission = yield this.hasLocationPermission();
                console.log("hasLocationPermission ::", hasLocationPermission);
                if (!hasLocationPermission) {
                    this.allowLoactionAccess(false);
                    return;
                }
                const googleAPIkey = "AIzaSyAsVvsnqcCH2nhpRgyQrpJZYqxRcHkq6xc";
                this.setState({ isSpinnerShowing: true });
                const position = yield new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject, {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0
                    });
                });
                console.log("user current position", position.coords.latitude, position.coords.longitude);
                const googleMapApi = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${googleAPIkey}&language=en`;
                this.setState({
                    coordinatesLat: position.coords.latitude,
                    coordinatesLong: position.coords.longitude,
                });
                const locationData = yield this.getCurrentLocation(googleMapApi);
                console.log(locationData);
            }
            catch (error) {
                console.log(error);
            }
            finally {
                this.setState({ isSpinnerShowing: false });
            }
        });
        this.handleSelection = (id) => {
            this.state.teamSizeData.map((item) => {
                if (item.attributes.id === id) {
                    item.attributes.isSelected = true;
                    this.setState({ selectedTeamSize: item.attributes.id }, () => {
                        if (this.state.selectedTeamSize) {
                            this.setState({ selectedTeamSizeError: "" });
                        }
                    });
                    return item;
                }
                else {
                    item.attributes.isSelected = false;
                }
                console.log("108==>", ...this.state.teamSizeData);
                this.setState({ teamSizeData: [...this.state.teamSizeData] });
            });
        };
        this.getServiceProvidedName = () => {
            if (this.state.serviceProvided.service_provided) {
                return this.state.serviceProvided.service_provided;
            }
            else {
                return "";
            }
        };
        this.handleCategorySelection = (Id) => {
            this.state.categoryList.map((item) => {
                console.log("item==>88", item);
                if (item.attributes.id === Id) {
                    item.attributes.isSelected = true;
                    window.localStorage.setItem("selectedCategoryName", item.attributes.business_type);
                    this.setState({ selectedCategoryId: item.attributes.id, selectedCategoryName: item.attributes.business_type }, () => {
                        if (this.state.selectedCategoryId) {
                            this.setState({ selectedCategoryIdError: "" });
                        }
                    });
                    return item;
                }
                else {
                    item.attributes.isSelected = false;
                }
                console.log("108==>", ...this.state.categoryList);
                this.setState({ categoryList: [...this.state.categoryList] });
            });
        };
        this.validateField = (fieldName) => {
            switch (fieldName) {
                case "address":
                    return this.state.address === "" ? "Please enter the address" : "";
                case "cityName":
                    return this.state.cityName === "" ? "Please enter the city" : "";
                case "stateName":
                    return this.state.stateName === "" ? "Please enter the state" : "";
                case "postalCode":
                    return this.state.postalCode === "" ? "Please enter the postal code" : "";
                case "countryName":
                    return this.state.countryName === "" ? "Please enter the country" : "";
                default:
                    return "";
            }
        };
        this.validate = () => __awaiter(this, void 0, void 0, function* () {
            const { selectedTeamSize, selectedCategoryId, address, postalCode, cityName, stateName, countryName } = this.state;
            let errorState = {
                addressError: "",
                cityNameError: "",
                stateNameError: "",
                postalCodeError: "",
                countryNameError: "",
                selectedTeamSizeError: "",
                selectedCategoryIdError: ""
            };
            if (!selectedTeamSize)
                errorState.selectedTeamSizeError = "Please choose your team size";
            if (!selectedCategoryId)
                errorState.selectedCategoryIdError = "Please select your business type";
            if (!address)
                errorState.addressError = "Please enter the address";
            if (!cityName)
                errorState.cityNameError = "Please enter the city";
            if (!stateName)
                errorState.stateNameError = "Please enter the state";
            if (!postalCode)
                errorState.postalCodeError = "Please enter the postal code";
            if (!countryName)
                errorState.countryNameError = "Please enter the country";
            this.setState(errorState);
            if (selectedTeamSize && selectedCategoryId && address && postalCode && cityName && stateName && countryName) {
                yield this.PostBusinessTeamSizeAPICall();
            }
        });
        this.fetchBusinessTeamSizeAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiGetBusinessTeamSizeCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/business_team_size");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.fetchBusinessCategoryType = () => {
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiGetBusinessCategoryTypeCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/show_business_type");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.PostBusinessTeamSizeAPICall = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isloading: true });
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiPostBusinessTeamSizeCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/add_business_team_size?business_team_size_id=" + this.state.selectedTeamSize);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.PostBusinessTypeAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            if (this.state.selectedCategoryId === "") {
                this.setState({ selectedCategoryIdError: "Please select your business type" });
                return;
            }
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiPostBusinessTypeCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'account_block/add_business_type/' + this.state.selectedCategoryId);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'PUT');
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.PostBusinessLocationAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": 'application/json',
                token: this.state.token
            };
            const httpBody = {
                "data": {
                    "attributes": {
                        "city": this.state.cityName,
                        "state": this.state.stateName,
                        "country": this.state.countryName,
                        "address": this.state.address,
                        "full_address": this.state.businessLocation,
                        "pincode": this.state.postalCode,
                        "landmark": this.state.landmark,
                        "latitude": this.state.coordinatesLat || 0,
                        "longitude": this.state.coordinatesLong || 0
                    }
                }
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiPostBusinessLocationSetId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'account_block/add_location');
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            token: "",
            selectedTeamSizeError: "",
            selectedCategoryIdError: "",
            serviceProvided: "Men",
            selectedTeamSize: null,
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            selection: "",
            businessType: 'fitness',
            businessLocation: "",
            businessLocationError: "",
            address: "",
            addressError: "",
            landmark: "",
            landmarkError: "",
            postalCode: "",
            postalCodeError: "",
            cityName: "",
            cityNameError: "",
            stateName: "",
            stateNameError: "",
            countryName: "",
            countryNameError: "",
            isSpinnerShowing: false,
            allowAccess: false,
            coordinatesLat: null,
            coordinatesLong: null,
            selectedCategoryId: '',
            categoryList: [],
            selectedCategoryName: '',
            teamSizeData: [],
            serviceCategory: [],
            // Customizable Area Start
            isloading: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            // Customizable Area Start
            console.log("receive :", getName(MessageEnum.RestAPIResponceMessage), message.id);
            if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
                yield this.handleApiRequestResponse(message);
            }
        });
    }
    handleApiRequestResponse(message) {
        return __awaiter(this, void 0, void 0, function* () {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            switch (apiRequestCallId) {
                case this.apiGetBusinessTeamSizeCallId:
                    yield this.handleGetBusinessTeamSizeResponse(responseJson, errorReponse);
                    break;
                case this.apiGetBusinessCategoryTypeCallId:
                    yield this.handleGetBusinessCategoryTypeResponse(responseJson, errorReponse);
                    break;
                case this.apiPostBusinessTeamSizeCallId:
                    yield this.handlePostBusinessTeamSizeResponse(responseJson, errorReponse);
                    break;
                case this.apiPostBusinessTypeCallId:
                    yield this.handlePostBusinessTypeResponse(responseJson, errorReponse);
                    break;
                case this.apiPostBusinessLocationSetId:
                    yield this.handlePostBusinessLocationResponse(responseJson, errorReponse);
                    break;
                default:
                    break;
            }
        });
    }
    handleGetBusinessTeamSizeResponse(responseJson, errorReponse) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!responseJson.errors) {
                const teamSizeData = responseJson.data;
                const arr = teamSizeData.map((item) => (Object.assign(Object.assign({}, item), { attributes: Object.assign(Object.assign({}, item.attributes), { isSelected: false }) })));
                this.setState({ teamSizeData: arr });
            }
            else if (responseJson.errors[0].token) {
                console.log("responseJson:", responseJson.errors[0].token);
            }
            else {
                alert(errorReponse);
            }
        });
    }
    handleGetBusinessCategoryTypeResponse(responseJson, errorReponse) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responseJson === null) {
                alert(errorReponse);
                return;
            }
            if (responseJson.errors) {
                const errorMessage = responseJson.errors[0].token
                    ? responseJson.errors[0].token
                    : "Error";
                console.log(errorMessage, responseJson.errors);
                return;
            }
            const categoryList = responseJson.data.map((item) => (Object.assign(Object.assign({}, item), { attributes: Object.assign(Object.assign({}, item.attributes), { isSelected: false }) })));
            this.setState({
                categoryList,
                serviceProvided: responseJson.meta,
            });
            localStorage.setItem("serviceProvideFor", responseJson.meta.service_provided);
        });
    }
    handlePostBusinessTeamSizeResponse(responseJson, errorReponse) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responseJson) {
                if (!responseJson.errors) {
                    yield this.PostBusinessLocationAPICall();
                    console.log("responseJson TeamSize:", responseJson);
                }
                else if (responseJson.errors[0].token) {
                    console.log("responseJson:", responseJson.errors);
                }
            }
            else {
                alert(errorReponse);
            }
        });
    }
    handlePostBusinessTypeResponse(responseJson, errorReponse) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({
                isloading: false
            });
            if (responseJson && !responseJson.errors) {
                this.props.navigation.navigate("CustomisableBusinessServices", {
                    selectedCategoryId: this.state.selectedCategoryId,
                    CategoryName: this.state.selectedCategoryName
                });
                console.log("responseJson BusinessLocation:", responseJson);
            }
            else if (responseJson && responseJson.errors[0].token) {
                console.log("responseJson:", responseJson.errors[0].token);
            }
            else if (responseJson) {
                console.log("Error:", responseJson.errors);
            }
        });
    }
    handlePostBusinessLocationResponse(responseJson, errorReponse) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!responseJson) {
                return;
            }
            if (!responseJson.errors) {
                yield this.PostBusinessTypeAPICall();
                console.log("responseJson BusinessLocation:", responseJson);
            }
            else {
                const errorMessage = responseJson.errors[0].token || "Error";
                console.log("responseJson:", errorMessage);
            }
        });
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            if (typeof window !== "undefined") {
                let token = window.localStorage.getItem("token");
                this.setState({ token: token }, () => __awaiter(this, void 0, void 0, function* () {
                    yield this.fetchBusinessCategoryType();
                    yield this.fetchBusinessTeamSizeAPICall();
                    yield this.getLocation();
                }));
                window.scrollTo(0, 0);
            }
            // Customizable Area Start
            setTimeout(() => {
                let userType = localStorage.getItem("userType");
                let loggedInToken = localStorage.getItem("token");
                if (!loggedInToken || userType != "business") {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
            }, 2000);
            // Customizable Area End
        });
    }
}
