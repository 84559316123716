// Customizable Area Start
import React from "react";
import "../assets/Styles/landingPage.css";
import { withTranslation } from "react-i18next";
import TermsAndConditionsWebController from "./TermsAndConditionsWebController";
class TermsAndConditions extends TermsAndConditionsWebController {
    render() {
        var _a, _b;
        let { t } = this.props;
        let termsAndCondition = (_b = (_a = this.state.termsAndConditionsData) !== null && _a !== void 0 ? _a : localStorage.getItem("termsAndCondition")) !== null && _b !== void 0 ? _b : "";
        return (React.createElement("div", { className: "whole-main-div", style: { maxWidth: "100%", margin: 0, overflow: "scroll" } },
            React.createElement("div", { className: "contact-div", style: { maxWidth: "1440px", margin: "0 auto" } }, termsAndCondition ? (React.createElement("div", { className: "other-setting-div", style: {
                    width: "100%",
                    maxWidth: "100%",
                    marginLeft: 0,
                } },
                React.createElement("h1", { style: {
                        textAlign: "initial",
                    }, dangerouslySetInnerHTML: { __html: termsAndCondition } }))) : (React.createElement("h1", { style: {
                    textAlign: "initial",
                } }, t("Terms & Conditions"))))));
    }
}
export default withTranslation()(TermsAndConditions);
// Customizable Area End
