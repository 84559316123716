var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
export default class ManageTeamWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.handleReceive = (apiRequestCallId, responseJson, errorJson) => {
            if (apiRequestCallId === this.getTeamMemberListCallId) {
                this.setState(Object.assign(Object.assign({}, this.state), { allTeamMembers: responseJson.data }));
            }
        };
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.getTeamMemberListApi = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getTeamMemberListCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getTeamMembersListEndpoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleMemberSearch = (value) => {
            if (value === "") {
                this.getTeamMemberListApi();
            }
            const searchedTeamMember = this.state.allTeamMembers.filter((member) => {
                return (`${member.attributes.email_address}`
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                    `${member.attributes.first_name} ${member.attributes.last_name}`
                        .replace(/\s{2,}/g, " ")
                        .toLowerCase()
                        .includes(value.toLowerCase()));
            });
            this.setState(Object.assign(Object.assign({}, this.state), { searchValue: value, allTeamMembers: searchedTeamMember }));
        };
        this.handleAddTeamMember = (type, memberId = null) => {
            if (memberId) {
                this.setState(Object.assign(Object.assign({}, this.state), { isAddTeamMember: type, editableMemberId: memberId }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { isAddTeamMember: type }));
                if (type === "none") {
                    this.getTeamMemberListApi();
                }
            }
        };
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            loading: false,
            allTeamMembers: [],
            searchValue: "",
            serviceDetails: {},
            isAddTeamMember: "none",
            editableMemberId: null,
            unsubscribedUserModal: false,
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let isUserLoggedIn = localStorage.getItem("token") || "";
            let businessUser = localStorage.getItem("userType") || "";
            if (!isUserLoggedIn || businessUser != "business") {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                this.getTeamMemberListApi();
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                if (apiRequestCallId === this.getTeamMemberListCallId) {
                    this.handleReceive(apiRequestCallId, responseJson, errorReponse);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
