var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
const Cookies = require("js-cookie");
export default class TermsAndConditionsWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.handleReceive = (apiRequestCallId, responseJson) => {
            if (apiRequestCallId === this.termsAndConditionsApiCallId) {
                this.handleTermsAndConditionsResponse(responseJson);
            }
        };
        this.handleTermsAndConditionsResponse = (responseJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                localStorage.setItem("termsAndCondition", responseJson.data[0].attributes.description);
                this.setState({
                    termsAndConditionsData: responseJson.data[0].attributes.description,
                });
            }
        };
        this.getTermsAndConditionsApi = () => {
            let webAppLanguage = localStorage.getItem("webAppLanguage") ||
                Cookies.get("webAppLanguage") ||
                "en";
            const header = {
                "Content-Type": "application/json",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.termsAndConditionsApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_settings/terms_and_conditions?language=${webAppLanguage}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            termsAndConditionsData: "",
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            this.getTermsAndConditionsApi();
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.termsAndConditionsApiCallId) {
                    this.handleReceive(apiRequestCallId, responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
